import React, { Component } from 'react'

import logo from '../../../assets/logo-black.svg';

import './style.css'

import PageContainer from '../../containers/PageContainer'


class BookTable extends Component {

renderBookingFrameOnDesktop() {
  if(this.props.width >= 600) {
    return (
      <div className='main-container'>
        <iframe title="easytablebooking" src="https://book.easytablebooking.com/book/?id=8eb93&lang=auto" frameborder="0" height="940" className="booking-iframe"></iframe>
      </div>
    )
  }
  return (
    <div>

    </div>
  )
}

renderContent() {
  return (
    <div>
      <a href="/">
        <img src={logo} className="logo-top" alt='galai' />
      </a>
      {this.renderBookingFrameOnDesktop()}
    </div>
  )
}


  render() {
    const { width } = this.props

    return(
      <PageContainer
        width={width}
        content={this.renderContent()}
      />
    )
  }
}

export default BookTable;
