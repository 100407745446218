// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// skai2019

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "galai-81574.firebaseapp.com",
  databaseURL: "https://galai-81574-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "galai-81574",
  storageBucket: "galai-81574.appspot.com",
  messagingSenderId: "729300754104",
  appId: "1:729300754104:web:35aa3bf41acaf933495e4d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);