import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import BookTable from "./pages/BookTable"
import Company from "./pages/Company"
import Admin from "./pages/Admin"

/*
  This component handles the routing and URL-handeling
*/
class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  /*
    Render functions
  */
  render() {
    return (
      <Routes>
        <Route
          exact path={"/"}
          element={
            <Home
              width={this.state.width}
              height={this.state.height}
            />
          }
        />
        <Route
          path={"/om-oss"}
          element={
            <About
              width={this.state.width}
              height={this.state.height}
            />
          }
        />
        <Route
          path={"/boka-bord"}
          element={
            <BookTable
              width={this.state.width}
              height={this.state.height}
            />
          }
        />
        <Route
          path={"/for-foretag"}
          element={
            <Company
              width={this.state.width}
              height={this.state.height}
            />
          }
        />
        <Route
          path={"/admin"}
          element={
            <Admin />
          }
        />
        <Route path="*" element={
          <div>Ooops, something went wrong!</div>
        } />
      </Routes>
    );
  }
}


export default Router;
