import React, { Component } from 'react'

import logo from '../../../assets/logo-black.svg';

import './style.css'

import PageContainer from '../../containers/PageContainer'

class Company extends Component {

renderForm() {
  if(this.props.width >= 600) {
    return (
      <div className='main-container'>
      <div className="form-card">
        Om ni är intresserade av att abonnera eller bara vill ha
        årets roligaste företags event
        så är det bara att skicka iväg ett mail till <a className="email-address" href="mailto:info@restauranggalai.se">info@restauranggalai.se</a>.
        <br/><br/>
        Vi ses!
      </div>
      </div>
    )
  }
  return (
    <div className='main-container'>
      <div className="form-card">
        Om ni är intresserade av att abonnera eller bara vill ha
        årets roligaste företags event
        så är det bara att skicka iväg ett mail till <a className="email-address" href="mailto:info@restauranggalai.se">info@restauranggalai.se</a>.
        <br/><br/>
        Vi ses!
      </div>
    </div>
  )
}

renderContent() {
  return (
    <div>
    <a href="/">
      <img src={logo} className="logo-top" alt='galai' />
    </a>
    {this.renderForm()}
    </div>
  )
}

  render() {
    const { width } = this.props

    return(
      <PageContainer
        width={width}
        content={this.renderContent()}
      />
    )
  }
}

export default Company;
