import React, { Component } from 'react'

import logoWhite from '../../../assets/logo-white.svg';

import './style.css'

import PageContainer from '../../containers/PageContainer'
import BouncingArrow from '../../containers/BouncingArrow';

import { db } from '../../../firebase'
import { onValue, ref } from 'firebase/database'

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: ""
    };
  }

  componentDidMount() {
    this.tryToFetchData()
  }

  tryToFetchData() {
    const query = ref(db, `users/BdPxCdl18Qb2n9oEEyaGr8VHg5a2/data/data`);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        this.setState({data})
      }
    });
  }


  renderContent() {
    const { width } = this.props
    return (
      <div className="main-outer-wrapper">
        <div className='main-container'>
          <div className="rust-container">
            <img src={logoWhite} className="logo" alt='galai' />
            <div className="mini-meny">
              <a className="center-menu-items" href="assets/Galai-meny.pdf" target="_blank">Meny</a>
              <span className="center-menu-items"> - </span>
              {
                width >= 600
                ? <a className="center-menu-items" href="/boka-bord">Boka bord</a>
                : <a className="center-menu-items" rel="noreferrer" target="_blank" href="https://book.easytablebooking.com/book/?id=8eb93&lang=auto">Boka bord</a>
              }
            </div>
          </div>
        </div>
        <div id="happening" className="happenings">
          <div className="happening-inner-container">
            <span className="text-object" dangerouslySetInnerHTML={{ __html: this.state.data }} />
          </div>
        </div>
        <BouncingArrow />
      </div>
    )
  }

  /*
  <div className="weekly-box">
    <p>Veckans lunch: Smörstekt torsk med potatis och bla bla bla</p>
    <p>Veckans jippo: Maskeradfest</p>
  </div>
  */

  render() {
    const { width } = this.props

    return(
      <PageContainer
        width={width}
        content={this.renderContent()}
      />
    )
  }
}

export default Home;
