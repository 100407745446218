import React, { Component } from 'react'

import logo from '../../../assets/logo-black.svg';

import './style.css'

import PageContainer from '../../containers/PageContainer'

class About extends Component {

  renderContent() {
    return (
      <div>
        <a href="/">
          <img src={logo} className="logo-top" alt='galai' />
        </a>
        <div className='main-container'>
        Vi på GALAI, <br/>
        vi har skapat en krog baserat på vad vi gillar!
        Det ska vara hög volym och käk som passar de flesta, men framförallt ska det vara högt i tak mellan stab och gäst! <br/><br/>
        Många av våra rätter går att dela i sällskapet såväl som att beställas för en person. Det finns allt från kött till skaldjur, eller varför inte en mosbricka som varierar från säsong?
        Luta er tillbaka och njut, vi fixar resten!<br/><br/>
        Skål och välkomna.
        </div>
      </div>
    )
  }


  render() {
    const { width } = this.props

    return(
      <PageContainer
        width={width}
        content={this.renderContent()}
      />
    )
  }
}

export default About;
