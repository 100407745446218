import React, { useState, useEffect } from 'react';
import './BouncingArrow.css'; // Import CSS file for styling
import { DownCircleOutlined } from '@ant-design/icons';

const BouncingArrow = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [weekNumber, setWeekNumber] = useState("")

  useEffect(() => {

    var currentDate = new Date();
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil(days / 7) + 1;

    setWeekNumber(weekNumber)

    // Add scroll event listener to handle fading out
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Calculate how far user has scrolled
    const scrollY = window.scrollY || window.pageYOffset;
    // Set visibility based on scroll position
    setIsVisible(scrollY === 0);
  };


  return (
    <div className={`bouncing-arrow ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="arrow-container">
      <span className="text">Detta händer vecka {weekNumber}</span>
      <div className="arrow"><DownCircleOutlined /></div>
      </div>
    </div>
  );
};

export default BouncingArrow;
