import React, { useEffect, useState} from 'react'
import { Card, Input, Space, Button } from "antd";
import { auth } from '../../../firebase'
import { db } from '../../../firebase'
import { set, ref, onValue } from 'firebase/database'
import { signInWithEmailAndPassword } from "firebase/auth";
const { TextArea } = Input;

const Admin = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [signedIn, setSignedIn] = useState(false)
    const [user, setUser] = useState("")
    const [data, setData] = useState("")


    useEffect(() => {
        const tryToFetchData = () => {
            const query = ref(db, `users/BdPxCdl18Qb2n9oEEyaGr8VHg5a2/data/data`);
            return onValue(query, (snapshot) => {
                if (snapshot.exists()) {
                    setData(snapshot.val());
                }
            });
        };
        
        tryToFetchData();
    }, []);

    const handleLogIn = () => {
        signInWithEmailAndPassword(auth, username, password).then((userCredential) => {
            const user = userCredential.user
            setSignedIn(true)
            setUser(user)
        }).catch((err) => {
            setSignedIn(false)
            console.log(err.code)
            console.log(err.message)
        })
    }

    const handleOnSave = () => {
        set(ref(db, `users/${user.uid}/data`), {data});
    }

    if(signedIn) {
        return (
            <div style={{flexDirection:'column',display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', marginBottom: '10%'}}>
            <Space
            direction="vertical"
            size="middle"
            style={{
            display: 'flex',
            }}
        >
            <div>Welcome! {user.email}</div>
            <TextArea rows={20} value={data} onChange={e => setData(e.target.value)}/>
            <p><b>Example</b></p>
            <div id="happening" className="happenings">
                <div className="happening-inner-container">
                    <span className="text-object" dangerouslySetInnerHTML={{ __html: data }} />
                </div>
            </div>
            <Button onClick={handleOnSave} type="primary" style={{width: '100%'}}>Spara</Button>
            </Space>
            </div>
        )
    }

    return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
        <Card style={{ width: 300 }}>
        <Space
            direction="vertical"
            size="middle"
            style={{
            display: 'flex',
            }}
        >
        <Input placeholder="Email" value={username} onChange={e => setUsername(e.target.value)}/>
        <Space />
        <Input.Password placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
        <Space />
        <Button onClick={handleLogIn} type="primary" style={{width: '100%'}}>Log in</Button>
        </Space>
        </Card>   
    </div>
    )
}

export default Admin