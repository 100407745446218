import React, { Component } from 'react'
import { Slant as Hamburger } from 'hamburger-react'

import logoSilver from '../../../assets/logo-neg-silver.svg';

import './style.css'

class PageContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      data: {
        line1: "",
        line2: "",
        line3: "",
        line4: "",
        line5: "",
        line6:""
       }
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const res = await fetch(`assets/openingHours.json`);
    const data = await res.json();
    return this.setState({ data });
  }


showMenu() {
  const { width } = this.props
  if(this.state.showMenu) {
    return (
      <div className={`${this.state.showMenu ? 'menu-container' : 'hide-container'}`}>
        <div className="burger-menu-style">
          {
            width >= 600
            ? <a className="burger-menu-items" href="/boka-bord">Boka bord</a>
            : <a className="burger-menu-items" target="_blank" href="https://book.easytablebooking.com/book/?id=8eb93&lang=auto">Boka bord</a>
          }
          <a className="burger-menu-items" href="assets/Galai-meny.pdf" target="_blank">Meny</a>
          <a className="burger-menu-items" href="/om-oss">Om</a>
          <a className="burger-menu-items" href="/for-foretag">För företag</a>
        </div>
      </div>
    )
  }
  return (<div></div>)
}

showFooter() {
  if(this.state.showMenu) {
    return (<div></div>)
  }
  else {
    return (
      <div className='footer-container'>
        <div className="footer-contact-info">
          <div><img src={logoSilver} className="footer-logo" alt='galai' /></div>
          <div><a className="adress" href="">Teatertorget</a></div>
          <div><a>info@restauranggalai.se</a></div>
          <div><a className="phone-number" href="tel:+46470524144">0470-524144</a></div>
        </div>
        <div className="footer-opening-hours">
          <div className="footer-opening-hour-day">
          {this.state.data.line1}
          </div>
          <div className="footer-opening-hour-day">
          {this.state.data.line2}
          </div>
          <div className="footer-opening-hour-day">
          {this.state.data.line3}
          </div>
          <div className="footer-opening-hour-day">
          {this.state.data.line4}
          </div>
          <div className="footer-opening-hour-day">
          {this.state.data.line5}
          </div>
          <div className="footer-opening-hour-day">
          {this.state.data.line6}
          </div>
        </div>
      </div>
    )
  }
}


  render() {
    const { width } = this.props

    return(
      <div className='main-outer-container'>
        <div style={{position: 'absolute', top: 5, left: 10, zIndex: 101}}>
          <Hamburger toggled={this.state.showMenu} onToggle={() => this.setState({showMenu: !this.state.showMenu})} />
        </div>
        {this.showMenu()}
          {this.props.content}
          {this.showFooter()}
        </div>
    )
  }
}

export default PageContainer;
